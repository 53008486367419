import React from 'react';
import { Box, Grid, Divider } from '@mui/material';
import { SideNav } from '../components/layout_components/sidenav';
import { HeaderComponent } from '../components/layout_components/headerComponent';
import { Outlet } from "react-router-dom";
import Footer from '../components/Footer';

export const Layout = () => {
    return (
        <Box sx={{ display: 'flex', height: '100vh' }}>
            <Grid item md={2} sx={{ height: '100vh', overflow: 'hidden' }}>
                <SideNav />
            </Grid>
            <Grid item md={10} container justifyContent={'center'} className='layoutPage'>
                <Grid item md={10} sx={{ height: '100vh', display: 'flex', flexDirection: 'column' }}>
                    <Grid sx={{ height: '10vh' }}>
                        <HeaderComponent />
                    </Grid>
                    <Divider orientation="horizontal" sx={{ margin: '0 20px' }} />
                    <Grid sx={{ height: '90vh', overflowY: 'auto' }}> {/* Enable vertical scrolling here */}
                        <Outlet />
                        {/* <Footer/> */}
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
}
                       