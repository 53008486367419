import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  TextField,
  Button,
  FormControl,
  MenuItem,
  Select,
} from "@mui/material";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import { useNavigate } from "react-router-dom";
import { ManagementServices } from "../services/managementServices";
import MeetIcon from "../assets/meeticon.svg";
import { useForm, Controller } from "react-hook-form";
import { SuccessAlert, ErrorAlert } from "../components/alert";
import { BackdropWithLottie } from "../components/lottie";
export const CreateEvent = () => {
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [time, setTime] = useState("15 mins");
  const [color, setColor] = React.useState("#8247F5");
  const [userDetails, setUserDetails] = useState("");
  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
    reset,
  } = useForm({
    defaultValues: {
      eventName: "",
      customDuration: "",
      customUnit: "mins",
    },
  });
  const [alrtMsg, setAlrtMsg] = useState("");
  const [successAlrtOpen, setSuccessAlrtOpen] = useState(false);
  const [errorAlrtOpen, setErrorAlrtOpen] = useState(false);
  const [openLottie, setOpenLottie] = useState(false);

  const handleCloseLottie = () => {
    setOpenLottie(false);
  };

  const closeSuccess = () => {
    setSuccessAlrtOpen(false);
  };

  const closeError = () => {
    setErrorAlrtOpen(false);
  };

  const handleChange = (event) => {
    setTime(event.target.value);
    if (event.target.value !== "custom") {
      setError("");
    }
  };
  const handleChangeColor = (event) => {
    setColor(event.target.value);
  };
  const formatDuration = (duration, unit) => {
    if (unit === "mins") {
      const hours = Math.floor(duration / 60);
      const minutes = duration % 60;
      return hours > 0
        ? `${hours} hr${hours > 1 ? "s" : ""} ${minutes} min${
            minutes > 0 ? "s" : ""
          }`
        : `${minutes} min${minutes > 1 ? "s" : ""}`;
    }
    return `${duration} hr${duration > 1 ? "s" : ""}`;
  };

  const onSubmit = async (data) => {
    if (time === "custom") {
      const customDuration = Number(data.customDuration);
      const customUnit = data.customUnit;

      if (customUnit === "hours" && customDuration > 12) {
        setError("Must be less than or equal to 12 hours");
        return;
      }

      if (customDuration <= 0) {
        setError("Custom duration must be greater than 0");
        return;
      }
    }

    setOpenLottie(true);
    try {
      const duration = time === "custom" ? `${data.customDuration} ${data.customUnit}` : time;
      const response = await ManagementServices.eventAdd({
        name: data.eventName,
        duration,
        location: "Google Meet",
        userId: userDetails?.id,
        email: userDetails?.email,
        colorCode: color,
        loginType: userDetails?.googleId ? 'google' : 'microsoft', // determine login type
        ...(userDetails?.googleId ? { googleId: userDetails?.googleId } : { microsoftId: userDetails?.microsoftId }),
        myLinkName: userDetails?.myLinkName
      });

      setOpenLottie(false);
      setSuccessAlrtOpen(response.status === 1);
      setErrorAlrtOpen(response.status !== 1);
      setAlrtMsg(response.message);

      if (response.status === 1) {
        reset({ eventName: "", customDuration: "", customUnit: "mins", color: "#8247F5" });
        setTimeout(() => navigate("/app/events"), 500);
      }
    } catch (error) {
      console.log(error);
      setOpenLottie(false);
      setErrorAlrtOpen(true);
      setAlrtMsg("An error occurred while creating the event.");
    }
  };

  useEffect(() => {
    const storedUser = localStorage.getItem("user");
    if (storedUser) {
      const userDetails = JSON.parse(storedUser);
      setUserDetails(userDetails);
    } else {
      console.error("No userDetails found in session storage");
    }
    setError("");
  }, []);

  return (
    <>
      <Grid>
        <Grid container>
          <Grid md={3} className="createEventSidenav">
            <Grid container justifyContent={"space-between"}>
              <Grid item p={4} className="createEventSidenavHeader" sx={{ width: "100%" }}>
                <Grid container>
                  <Grid container item sx={{ cursor: "pointer" }} onClick={() => navigate("/app/events")}>
                    <KeyboardArrowLeftIcon />
                    <Typography>Cancel</Typography>
                  </Grid>
                </Grid>
                <Grid item component={"h1"} className="newEventType">
                  New Event Type
                </Grid>
              </Grid>
              <Grid component={"form"} md={12} onSubmit={handleSubmit(onSubmit)}>
                <Grid item p={4}>
                  <Grid className="labelName">Event name</Grid>
                  <Grid container alignItems={"center"}>
                    <Grid item md={3}>
                      <FormControl fullWidth className="formControl">
                        <Select
                          labelId="time-select-label"
                          fullWidth
                          id="time-select"
                          value={color}
                          onChange={handleChangeColor}
                          IconComponent={ExpandMoreIcon}
                          sx={{
                            borderRadius: "10px 0 0 10px",
                            backgroundColor: "white",
                            height: "45px",
                            marginTop: "-10px",
                            "& .MuiSelect-select": {
                              display: "flex",
                              alignItems: "center",
                            },
                          }}
                          MenuProps={{
                            PaperProps: {
                              sx: {
                                maxHeight: "300px", // Adjust as needed
                                padding: 0,
                                "& .MuiMenuItem-root": {
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  flex: "1 0 20%",
                                },
                              },
                            },
                          }}
                          placeholder="Select time"
                        >
                          <MenuItem value={"#FF4F00"}>
                            <Grid className={"color1"}></Grid>
                          </MenuItem>
                          <MenuItem value={"#FF758E"}>
                            <Grid className={"color2"}></Grid>
                          </MenuItem>

                          <MenuItem value={"#E55CFF"}>
                            <Grid className={"color3"}></Grid>
                          </MenuItem>
                          <MenuItem value={"#8247F5"}>
                            <Grid className={"color4"}></Grid>
                          </MenuItem>
                          <MenuItem value={"#0099FF"}>
                            <Grid className={"color5"}></Grid>
                          </MenuItem>
                          <MenuItem value={"#0AE8F0"}>
                            <Grid className={"color6"}></Grid>
                          </MenuItem>
                          <MenuItem value={"#17E885"}>
                            <Grid className={"color7"}></Grid>
                          </MenuItem>
                          <MenuItem value={"#ccf000"}>
                            <Grid className={"color8"}></Grid>
                          </MenuItem>
                          <MenuItem value={"#f8e436"}>
                            <Grid className={"color9"}></Grid>
                          </MenuItem>
                          <MenuItem value={"#ffa600"}>
                            <Grid className={"color10"}></Grid>
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item md={9}>
                      <FormControl fullWidth>
                      <Controller
                          name="eventName"
                          control={control}
                          defaultValue=""
                          rules={{ required: "Event name is required" }}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              variant="outlined"
                              placeholder="Name your event"
                              fullWidth
                              size="medium"
                              className=" event1"
                              error={!!errors.eventName}
                              helperText={errors.eventName?.message}
                              InputProps={{
                                sx: {
                                  borderRadius: "0 10px 10px 0", // Example border radius
                                },
                              }}
                            />
                          )}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>

                  <FormControl fullWidth className="formControl">
                    <Grid className="labelName">Duration</Grid>
                    <Select
                      labelId="time-select-label"
                      id="time-select"
                      value={time}
                      onChange={handleChange}
                      IconComponent={ExpandMoreIcon}
                      sx={{ borderRadius: "10px" }}
                      className="eventSelectField"
                    >
                      <MenuItem value="15 mins">15 mins</MenuItem>
                      <MenuItem value="30 mins">30 mins</MenuItem>
                      <MenuItem value="45 mins">45 mins</MenuItem>
                      <MenuItem value="60 mins">60 mins</MenuItem>
                      <MenuItem value="custom">Custom</MenuItem>
                    </Select>
                  </FormControl>
                  {time === "custom" && (
                    <Grid container spacing={2} style={{ marginTop: "10px" }}>
                      <Grid item xs={6}>
                        <Controller
                          name="customDuration"
                          control={control}
                          defaultValue=""
                          rules={{ required: "Custom duration is required" }}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              label="Custom Duration"
                              variant="outlined"
                              fullWidth
                              type="number"
                              error={!!errors.customDuration || !!error}
                              helperText={
                                errors.customDuration?.message || error
                              }
                              onChange={(e) => {
                                const value = Number(e.target.value);
                                field.onChange(e);

                                if (
                                  watch("customUnit") === "hours" &&
                                  value > 12
                                ) {
                                  setError(
                                    "Must be less than or equal to 12 hours"
                                  );
                                } else {
                                  setError("");
                                }
                              }}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Controller
                          name="customUnit"
                          control={control}
                          defaultValue="mins"
                          rules={{ required: "Custom unit is required" }}
                          render={({ field }) => (
                            <FormControl fullWidth>
                              <Select
                                {...field}
                                variant="outlined"
                                sx={{ borderRadius: "10px" }}
                                onChange={(e) => {
                                  const value = e.target.value;
                                  field.onChange(e);

                                  if (
                                    value === "hours" &&
                                    watch("customDuration") > 12
                                  ) {
                                    setError(
                                      "Must be less than or equal to 12 hours"
                                    );
                                  } else {
                                    setError("");
                                  }
                                }}
                              >
                                <MenuItem value="mins">Mins</MenuItem>
                                <MenuItem value="hours">Hours</MenuItem>
                              </Select>
                            </FormControl>
                          )}
                        />
                      </Grid>
                    </Grid>
                  )}
                  <FormControl fullWidth className="formControl">
                    <Grid className="labelName">Location</Grid>
                    <Grid container gap={1} pt={2}>
                      <Grid item className="meetIcon">
                        <img src={MeetIcon} alt="meetIcon" />
                      </Grid>
                      <Grid item className="timeText">
                        Google Meet
                      </Grid>
                    </Grid>
                  </FormControl>
                </Grid>
                <Grid item p={4}>
                  <Grid
                    container
                    justifyContent={"end"}
                    alignItems={"center"}
                    gap={2}
                  >
                    <Typography
                      sx={{ cursor: "pointer" }}
                      onClick={() => navigate("/app/events")}
                    >
                      Cancel
                    </Typography>
                    <Button className="continueBtn" type="submit">
                      Continue
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid md={9} container alignItems={"center"} sx={{ height: "100vh" }}>
            <Grid
              container
              justifyContent={"center"}
              alignItems={"center"}
              sx={{ height: "80%" }}
            >
              <Grid md={8} className="createCard" sx={{ height: "100%" }}>
                <Grid className="cardHeader" sx={{ height: "8%" }}>
                  <Typography component={"span"} className="cardHeaderSpan">
                    This is a preview.
                  </Typography>
                  <Typography component={"span"}>
                    To book an event, share the link with your invitees.
                  </Typography>
                </Grid>
                <Grid container sx={{ height: "92%" }}>
                  <Grid item md={6} p={3} className="cardBodyHalf">
                    <Typography className="cardBodyProfileName">
                      {userDetails.name}
                    </Typography>
                    <Typography className="eventName">
                      {watch("eventName") || "Event name here"}
                    </Typography>
                    <Grid container gap={2} pt={3}>
                      <AccessTimeIcon className="timeIcon" />
                      <Typography className="timeText">
                        {time === "custom" ? formatDuration( Number(watch("customDuration")),
                              watch("customUnit")
                            )
                          : time}
                      </Typography>
                    </Grid>
                    <Grid container gap={2} pt={3}>
                      <LocationOnOutlinedIcon className="timeIcon" />
                      <Typography className="timeText">Google Meet</Typography>
                    </Grid>
                  </Grid>
                  <Grid item md={6} container justifyContent={"center"} alignItems={"center"}>
                    <Grid container justifyContent={"center"}>
                      <Grid item md={8}>
                        <Typography className="previewText">
                          A preview of your availability will show on the next
                          step
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <SuccessAlert alertMsg={alrtMsg} open={successAlrtOpen} close={closeSuccess} />
      <ErrorAlert alertMsg={alrtMsg} open={errorAlrtOpen} close={closeError} />
      <BackdropWithLottie open={openLottie} onClose={handleCloseLottie} />
    </>
  );
};
