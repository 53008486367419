import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  Button,
  Container,
  Autocomplete,
  TextField,
  Divider,
} from "@mui/material";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import dayjs from "dayjs";
import { EditEventDetails } from "../components/editEventDetails";
import { EditScheduleSettings } from "../components/editScheduleSettings";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { useNavigate } from "react-router-dom";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import MenuIcon from "@mui/icons-material/Menu";
import { ManagementServices } from "../services/managementServices";
import { Calendar } from "react-multi-date-picker";
import { useParams } from "react-router-dom";
import LaunchIcon from "@mui/icons-material/Launch";
import { formatDuration } from "../utils/timeFormate";
import { BackdropWithLottie } from "../components/lottie";
import { AxiosClient } from "../axios/client";
import moment from "moment-timezone";
export const EditEvent = () => {
  const [showEventDetails, setShowEventDetails] = useState(false);
  const [showScheduleSettings, setShowScheduleSettings] = useState(false);
  const [editEvent, setEditEvent] = useState(true);
  const [availableSlots, setAvailableSlots] = useState({});
  const [selectedDate, setSelectedDate] = useState(dayjs());
  const [alrtMsg, setAlrtMsg] = useState("");
  const [errorAlrtOpen, setErrorAlrtOpen] = useState(false);
  const [availableDates, setAvailableDates] = useState([]);
  const [animation, setAnimation] = useState("slide-out-right");
  const [timeSlots, setTimeSlots] = useState([]);
  const [visibility, setVisibility] = useState([]);
  const [selectedTimeZone, setSelectedTimeZone] = useState("");
  const [selectedTimeSlot, setSelectedTimeSlot] = useState(null);
  const [brandingLogo, setBrandingLogo] = useState("");
  const [organizationName, setOrganizationName] = useState("");
  const [eventSlot, setEventSlot] = useState({});
  const { myLinkName, eventId } = useParams();
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem("user"));
  const [openLottie, setOpenLottie] = useState(false);
  const [eventTimeZone, setEventTimeZone] = useState("");
  const handleCloseLottie = () => {
    setOpenLottie(false);
  };
  const handleEventDetails = () => {
    setShowEventDetails(!showEventDetails);
    setEditEvent(!editEvent);
  };
  const editEventSidenav = () => {
    setEditEvent(!editEvent);
    setShowEventDetails(!showEventDetails);
  };
  const allTimeZones = moment.tz.names();
  const editScheduleSidenav = () => {
    setEditEvent(!editEvent);
    setShowScheduleSettings(!showScheduleSettings);
  };

  const fetchEventSlotDetails = async () => {
    const id = eventId;
    setOpenLottie(true);
    try {
      const response = await ManagementServices.getEventSlotDetails(
        myLinkName,
        id
      );
      if (response.status === 1) {
        setEventSlot(response.data.event);
        setAvailableSlots(response.data.availableSlots);
        const keys = Object.keys(response.data.availableSlots);
        const dates = keys.map((dateStr) => dayjs(dateStr));
        setAvailableDates(dates);
      } else {
        setAlrtMsg(response.message);
        setErrorAlrtOpen(true);
      }
    } catch (error) {
      console.error(error);
      setAlrtMsg("An error occurred while fetching event details.");
      setErrorAlrtOpen(true);
    } finally {
      setOpenLottie(false);
    }
  };

  useEffect(() => {
    fetchEventSlotDetails();
  }, [eventId, myLinkName]);

  useEffect(() => {
    const formattedDate = selectedDate.format("YYYY-MM-DD");
    if (availableSlots[formattedDate]) {
      setTimeSlots(availableSlots[formattedDate]);
      setVisibility(new Array(availableSlots[formattedDate].length).fill(true));
    } else {
      setTimeSlots([]);
      setVisibility([]);
    }
  }, [selectedDate, availableSlots]);

  const handlePreview = () => {
    window.open(`/${user.myLinkName}/${eventId}`, "_blank");
  };

  useEffect(() => {
    if (selectedDate) {
      const formattedDate = selectedDate.format("YYYY-MM-DD");
      if (availableSlots[formattedDate]) {
        setTimeSlots(availableSlots[formattedDate]);
        setVisibility(
          new Array(availableSlots[formattedDate].length).fill(true)
        );
      } else {
        console.log("No available slots for selected date.");
        setTimeSlots([]);
        setVisibility([]);
      }
    }
  }, [selectedDate, availableSlots]);

  const fetchEventDetails = async () => {
    try {
      const response = await ManagementServices.getEventSlotDetails(eventId);
      if (response.status === 1) {
        setTimeSlots(response.data.availableSlots); // Assuming availableSlots is an array of time slots
      }
    } catch (error) {
      console.error("Error fetching event details:", error);
    }
  };

  useEffect(() => {
    fetchEventDetails();
  }, [eventId]);

  const handleButtonClick = (index) => {
    const newVisibility = visibility.map((_, i) =>
      i === index ? false : true
    );
    setVisibility(newVisibility);
    setAnimation("slide-out-right");
    setSelectedTimeSlot(timeSlots[index]);
  };

  const handleResetClick = (index) => {
    const newVisibility = visibility.map((_, i) =>
      i === index ? true : visibility[i]
    );
    setVisibility(newVisibility);
    setAnimation("slide-out-right");
  };

  const handleNextClick = () => {
    if (selectedTimeSlot) {
      const startTime = selectedTimeSlot.start;
      const endTime = selectedTimeSlot.end || "Unknown End Time";
      const formattedDate = selectedDate.format("dddd, MMMM D, YYYY");
      const onlyDate = selectedDate.format("YYYY-MM-DD");
      const timeSlotString = `${startTime} - ${endTime}, ${formattedDate}`;
      navigate("/eventDetails", {
        state: {
          eventSlot,
          formattedDuration,
          startTime,
          endTime,
          formattedDate,
          onlyDate,
          timeSlotString,
          eventId,
        },
      });
    } else {
      console.error("No time slot selected.");
    }
  };
  const convertTimeSlotsToSelectedTimezone = (timeSlot) => {
    if (selectedTimeZone) {
      const startTimeInUTC = moment.tz(
        timeSlot.start,
        "hh:mm A", // Specify the correct format here (12-hour format with AM/PM)
        availableSlots.timezone
      );
      const endTimeInUTC = timeSlot.end
        ? moment.tz(timeSlot.end, "hh:mm A", availableSlots.timezone)
        : null;

      const startInTZ = startTimeInUTC.tz(selectedTimeZone).format("hh:mm A");
      const endInTZ = endTimeInUTC
        ? endTimeInUTC.tz(selectedTimeZone).format("hh:mm A")
        : "Unknown End Time";

      return `${startInTZ} - ${endInTZ}`;
    }

    return `${timeSlot.start} - ${timeSlot.end || "Unknown End Time"}`;
  };
  useEffect(() => {
    const fetchBrandDetails = async () => {
      const userId = localStorage.getItem("user")
        ? JSON.parse(localStorage.getItem("user")).id
        : null;

      // if (!userId) return;

      try {
        const response = await AxiosClient.post("viewBranchDetails", {
          userId,
        });
        if (response.data && response.data.status === 1) {
          setOrganizationName(response.data.data.organization_name);
          if (response.data.data.branding_logo) {
            const logoUrl = `${process.env.REACT_APP_IMAGES_URL}/brands/${response.data.data.branding_logo}`;
            setBrandingLogo(logoUrl);
          }
        } else {
          setErrorAlrtOpen(true);
          setAlrtMsg(
            response.data.message || "Failed to fetch branding details."
          );
        }
      } catch (error) {
        console.error("API Error:", error);
        setErrorAlrtOpen(true);
        setAlrtMsg("An error occurred while fetching branding details.");
      }
    };

    fetchBrandDetails();
  }, []);

  const AllTimeSlots = timeSlots.map((timeSlot, index) => (
    <Grid container m={2} key={index}>
      {visibility[index] ? (
        // <Button
        //   className={`timeBtn ${animation}`}
        //   onClick={() => handleButtonClick(index)}
        // >
        //   {`${timeSlot.start}`}
        // </Button>
        <Button
          className={`timeBtn ${animation}`}
          onClick={() => handleButtonClick(index)}
        >
          {convertTimeSlotsToSelectedTimezone(timeSlot)}
        </Button>
      ) : (
        <Grid container gap={2} className="slide-in">
          <Grid item md={5.5}>
            <Button
              className="timeBtn1"
              onClick={() => handleResetClick(index)}
            >
              {`${timeSlot.start}`}
            </Button>
          </Grid>
          <Grid item md={5.5}>
            <Button className="timeNxtBtn" onClick={handleNextClick}>
              Next
            </Button>
          </Grid>
        </Grid>
      )}
    </Grid>
  ));

  const handleTimeZoneChange = (event, newValue) => {
    setSelectedTimeZone(newValue);
  };

  const formattedDuration = formatDuration(eventSlot?.durationWithFormat);
  useEffect(() => {
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    setSelectedTimeZone(userTimeZone);
  }, []);
  return (
    <>
      <Grid container alignItems={"center"}>
        {editEvent && (
          <Grid md={3} className="createEventSidenav">
            <Grid>
              <Grid container justifyContent={"space-between"}>
                <Grid item p={4} className="createEventSidenavHeader" md={12}>
                  <Grid
                    container
                    alignItems={"center"}
                    justifyContent={"space-between"}
                  >
                    <Grid item>
                      <Grid
                        container
                        sx={{ cursor: "pointer" }}
                        onClick={() => navigate("/app/events")}
                      >
                        <KeyboardArrowLeftIcon />
                        <Typography>Cancel</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item component={"h1"} className="newEventType">
                    {eventSlot?.name}
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                container
                py={3}
                justifyContent={"space-between"}
                className="eventBorder"
                onClick={handleEventDetails}
              >
                <Grid item md={2} sx={{ textAlign: "center" }}>
                  <MenuIcon />
                </Grid>
                <Grid item md={7}>
                  <Typography className="eventType">Event details</Typography>
                  <Typography pt={2} className="timeText">
                    {formattedDuration}
                  </Typography>
                  <Typography pt={2} className="timeText">
                    {eventSlot?.location}
                  </Typography>
                </Grid>
                <Grid item md={2} container alignItems={"center"}>
                  <KeyboardArrowRightIcon />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
        {showEventDetails && (
          <Grid md={3} className="createEventSidenav editEventDetails">
            <EditEventDetails
              detailsEdit={editEventSidenav}
              eventDetails={eventSlot}
            />
          </Grid>
        )}
        {showScheduleSettings && (
          <Grid md={3} className="createEventSidenav editEventDetails">
            <EditScheduleSettings scheduleEdit={editScheduleSidenav} />
          </Grid>
        )}
        <Grid md={9}>
          <Container sx={{ height: "100vh" }} className="eventBlockAlign">
            <Grid sx={{ height: "80%", width: "100%" }}>
              <Grid container alignItems={"center"} sx={{ height: "100%" }}>
                <Grid md={12} className="createCard" sx={{ height: "100%" }}>
                  <Grid
                    container
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    className="cardHeader"
                    sx={{ height: "8%", cursor: "pointer" }}
                  >
                    <Grid item>
                      <Typography component={"span"} className="cardHeaderSpan">
                        This is a preview.
                      </Typography>
                      <Typography component={"span"}>
                        To book an event, share the link with your invitees.
                      </Typography>
                    </Grid>

                    <Grid item onClick={handlePreview}>
                      <Grid container gap={2}>
                        <LaunchIcon />
                        <Typography>View live page</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid container p={2} sx={{ height: "92%" }}>
                    <Grid item md={3.5} p={2} className="cardBodyHalf">
                      {brandingLogo ? (
                        <img
                          src={brandingLogo}
                          alt="Branding Logo"
                          className="brandingLogo"
                          style={{
                            width: "100%",
                            height: "250px",
                            objectFit: "scale-down",
                            marginTop: "-4rem",
                          }}
                        />
                      ) : (
                        <>
                          <Typography className="backSpaceText">
                            {organizationName}
                          </Typography>
                        </>
                      )}
                      <Grid mb={4} mt={-4}>
                        <Divider />
                      </Grid>
                      <Typography className="cardBodyProfileName">
                        {eventSlot?.userName}
                      </Typography>
                      <Typography className="eventName">
                        {eventSlot?.name}
                      </Typography>
                      <Grid container gap={2} pt={3}>
                        <AccessTimeIcon className="timeIcon" />
                        <Typography className="timeText">
                          {formattedDuration}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid item md={8.5} sx={{ height: "100%" }}>
                      <Grid sx={{ height: "10%" }}>
                        <Grid>
                          <Typography px={4} className="eventName">
                            Select a Date & Time
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid container sx={{ height: "90%" }}>
                        <Grid
                          container
                          item
                          md={7}
                          justifyContent={"center"}
                          sx={{ height: "100%" }}
                          className="calendarBlock"
                        >
                          <Calendar
                            value={selectedDate.toDate()}
                            onChange={(date) => setSelectedDate(dayjs(date))}
                            format="YYYY-MM-DD"
                            mapDays={({ date }) => {
                              let isAvailable = availableDates.some((avDate) =>
                                avDate.isSame(date, "day")
                              );
                              if (!isAvailable) {
                                return { disabled: true };
                              }
                              return { className: "highlighted-date" };
                            }}
                          />
                        </Grid>
                        <Grid item md={5} sx={{ height: "100%" }}>
                          <Typography>
                            {selectedDate
                              ? selectedDate.format("dddd, MMMM D")
                              : "Select a date"}
                          </Typography>
                          <Grid
                            className="timeBlock"
                            sx={{ height: "90%", textAlign: "center" }}
                          >
                            {AllTimeSlots && AllTimeSlots.length > 0 ? (
                              AllTimeSlots
                            ) : (
                              <Typography pt={5}>
                                No available time slots for the selected date.
                              </Typography>
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Container>
                {/* Timezone Picker Dropdown */}
                <Grid mt={4} md={12}>
                  <Typography variant="h6" fontWeight={700}>
                    Time Zone
                  </Typography>
                  <Autocomplete
                    value={selectedTimeZone}
                    onChange={(event, newValue) =>
                      setSelectedTimeZone(newValue)
                    }
                    options={allTimeZones}
                    getOptionLabel={(option) => option}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select Time Zone"
                        variant="outlined"
                        sx={{ minWidth: 300, marginTop: 1 }}
                      />
                    )}
                    sx={{ minWidth: 300, marginTop: 1 }}
                    isOptionEqualToValue={(option, value) => option === value}
                  />
                </Grid>
              </Container>
            </Grid>
          </Container>
        </Grid>
      </Grid>

      {/* <BackdropWithLottie open={openLottie} onClose={handleCloseLottie} /> */}
    </>
  );
};
