import { Container, Grid, Link } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();
  return (
    <Container>
      <Grid container mt={5} mb={1} direction={"column"}>
        <Grid item container>
          <Grid item md={3.9} xs={12}>
            <Grid item sx={{ color: "#1976d2" }}>
              © 2024 Krifly. All rights reserved.
            </Grid>
          </Grid>
          <Grid component={"span"}>|</Grid>

          <Grid item md={3.9} xs={12} alignSelf={"center"}>
            <Grid item className="loginText" mr={1}>
              <Link
                underline="none"
                onClick={() => {
                  navigate("/privacypolicy");
                }}
              >
                Privacy Policy
              </Link>
            </Grid>
          </Grid>
          <Grid component={"span"}>|</Grid>

          <Grid item md={4} xs={12} alignSelf={"center"}>
            <Grid item className="loginText" mr={1}>
              <Link
                underline="none"
                onClick={() => {
                  navigate("/termsandconditions");
                }}
              >
                Terms and Conditions
              </Link>
            </Grid>
          </Grid>
        </Grid>
        {/* <Grid item container mt={2} justifyContent={"center"} sx={{color: "rgb(11, 53, 88)"}}>
          © 2005 Krifly. All rights reserved.
        </Grid> */}
      </Grid>
    </Container>
  );
};

export default Footer;
