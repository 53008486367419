import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  Button,
  Container,
  Link,
  Autocomplete,
  TextField,
  Divider,
} from "@mui/material";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import dayjs from "dayjs";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { ManagementServices } from "../services/managementServices";
import { SuccessAlert, ErrorAlert } from "../components/alert";
import { Calendar } from "react-multi-date-picker";
import { InnerHeaderComponent } from "../components/layout_components/innerHeaderComponent";
import { formatDuration } from "../utils/timeFormate";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { BackdropWithLottie } from "../components/lottie";
import moment from "moment-timezone";
import { AxiosClient } from "../axios/client";

export const EventSlot = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { myLinkName, eventId } = useParams();
  const [alrtMsg, setAlrtMsg] = useState("");
  const [successAlrtOpen, setSuccessAlrtOpen] = useState(false);
  const [errorAlrtOpen, setErrorAlrtOpen] = useState(false);
  const [eventSlot, setEventSlot] = useState({});
  const [availableSlots, setAvailableSlots] = useState({});
  const [selectedDate, setSelectedDate] = useState(dayjs());
  const [availableDates, setAvailableDates] = useState([]);
  const [selectedTimeZone, setSelectedTimeZone] = useState("");
  const [timeSlots, setTimeSlots] = useState([]);
  const [visibility, setVisibility] = useState([]);
  const [animation, setAnimation] = useState("slide-out-right");
  const [selectedTimeSlot, setSelectedTimeSlot] = useState(null);
  const [brandingLogo, setBrandingLogo] = useState("");
  const [organizationName, setOrganizationName] = useState("");

  const user = JSON.parse(localStorage.getItem("user"));
  const dynamicLink = `${window.location.origin}/${myLinkName}/${eventId}`;

  const [openLottie, setOpenLottie] = useState(false);

  const handleCloseLottie = () => {
    setOpenLottie(false);
  };

  const closeSuccess = () => {
    setSuccessAlrtOpen(false);
  };

  const closeError = () => {
    setErrorAlrtOpen(false);
  };

  const allTimeZones = moment.tz.names();
  const handleButtonClick = (index) => {
    const newVisibility = visibility.map((_, i) =>
      i === index ? false : true
    );
    setVisibility(newVisibility);
    setAnimation("slide-out-right");
    setSelectedTimeSlot(timeSlots[index]);
  };

  const handleResetClick = (index) => {
    const newVisibility = visibility.map((_, i) =>
      i === index ? true : visibility[i]
    );
    setVisibility(newVisibility);
    setAnimation("slide-out-right");
  };

  const handleNextClick = () => {
    if (selectedTimeSlot) {
      const startTime = selectedTimeSlot.start;
      const endTime = selectedTimeSlot.end || "Unknown End Time";
      const formattedDate = selectedDate.format("dddd, MMMM D, YYYY");
      const onlyDate = selectedDate.format("YYYY-MM-DD");
      const timeSlotString = `${startTime} - ${endTime}, ${formattedDate}`;
      navigate("/eventDetails", {
        state: {
          eventSlot,
          formattedDuration,
          startTime,
          endTime,
          formattedDate,
          onlyDate,
          timeSlotString,
          eventId,
        },
      });
    } else {
      console.error("No time slot selected.");
    }
  };

  const eventSlotDetails = async () => {
    const id = eventId;
    setOpenLottie(true);
    try {
      const response = await ManagementServices.getEventSlotDetails(
        myLinkName,
        id
      );
      if (response.status === 1) {
        setEventSlot(response.data.event);
        setAvailableSlots(response.data.availableSlots);
        const keys = Object.keys(response.data.availableSlots);
        const dates = keys.map((dateStr) => dayjs(dateStr));
        setAvailableDates(dates);
      } else {
        setAlrtMsg(response.message);
        setErrorAlrtOpen(true);
      }
    } catch (error) {
      console.error(error);
      setAlrtMsg("An error occurred while fetching event details.");
      setErrorAlrtOpen(true);
    } finally {
      setOpenLottie(false);
    }
  };

  useEffect(() => {
    eventSlotDetails();
  }, []);

  useEffect(() => {
    if (selectedDate) {
      const formattedDate = selectedDate.format("YYYY-MM-DD");
      if (availableSlots[formattedDate]) {
        setTimeSlots(availableSlots[formattedDate]);
        setVisibility(
          new Array(availableSlots[formattedDate].length).fill(true)
        );
      } else {
        console.log("No available slots for selected date.");
        setTimeSlots([]);
        setVisibility([]);
      }
    }
  }, [selectedDate, availableSlots]);

  useEffect(() => {
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    setSelectedTimeZone(userTimeZone);
  }, []);

  const convertTimeSlotsToSelectedTimezone = (timeSlot) => {
    if (selectedTimeZone) {
      const startTimeInUTC = moment.tz(
        timeSlot.start,
        "hh:mm A", // Specify the correct format here (12-hour format with AM/PM)
        availableSlots.timezone
      );
      const endTimeInUTC = timeSlot.end
        ? moment.tz(timeSlot.end, "hh:mm A", availableSlots.timezone)
        : null;

      const startInTZ = startTimeInUTC.tz(selectedTimeZone).format("hh:mm A");
      const endInTZ = endTimeInUTC
        ? endTimeInUTC.tz(selectedTimeZone).format("hh:mm A")
        : "Unknown End Time";

      return `${startInTZ} - ${endInTZ}`;
    }

    return `${timeSlot.start} - ${timeSlot.end || "Unknown End Time"}`;
  };

  useEffect(() => {
    const fetchBrandDetails = async () => {
      const userId = localStorage.getItem("user")
        ? JSON.parse(localStorage.getItem("user")).id
        : null;

      if (!userId) return;

      try {
        const response = await AxiosClient.post("viewBranchDetails", {
          userId,
        });
        if (response.data && response.data.status === 1) {
          setOrganizationName(response.data.data.organization_name);
          if (response.data.data.branding_logo) {
            const logoUrl = `${process.env.REACT_APP_IMAGES_URL}/brands/${response.data.data.branding_logo}`;
            setBrandingLogo(logoUrl);
          }
        } else {
          setErrorAlrtOpen(true);
          setAlrtMsg(
            response.data.message || "Failed to fetch branding details."
          );
        }
      } catch (error) {
        console.error("API Error:", error);
        setErrorAlrtOpen(true);
        setAlrtMsg("An error occurred while fetching branding details.");
      }
    };

    fetchBrandDetails();
  }, []);

  // const AllTimeSlots = timeSlots.map((timeSlot, index) => {
  //   const timeSlotDisplay = convertTimeSlotsToSelectedTimezone(timeSlot);

  //   return (
  //     <Grid container m={2} key={index}>
  //       <Button
  //         className="timeBtn"
  //         onClick={() => setSelectedTimeSlot(timeSlot)}
  //       >
  //         {timeSlotDisplay}
  //       </Button>
  //     </Grid>
  //   );
  // });
  const AllTimeSlots = timeSlots.map((timeSlot, index) => (
    <Grid container m={2} key={index}>
      {visibility[index] ? (
        // <Button
        //   className={`timeBtn ${animation}`}
        //   onClick={() => handleButtonClick(index)}
        // >
        //   {`${timeSlot.start}`}
        // </Button>
        <Button
          className={`timeBtn ${animation}`}
          onClick={() => handleButtonClick(index)}
        >
          {convertTimeSlotsToSelectedTimezone(timeSlot)}
        </Button>
      ) : (
        <Grid container gap={2} className="slide-in">
          <Grid item md={5.5}>
            <Button
              className="timeBtn1"
              onClick={() => handleResetClick(index)}
            >
              {`${timeSlot.start}`}
            </Button>
          </Grid>
          <Grid item md={5.5}>
            <Button className="timeNxtBtn" onClick={handleNextClick}>
              Next
            </Button>
          </Grid>
        </Grid>
      )}
    </Grid>
  ));

  const formattedDuration =
    eventSlot && formatDuration(eventSlot?.durationWithFormat);

  return (
    <>
      <Grid sx={{ height: "100vh" }}>
        {user && (
          <InnerHeaderComponent linkToCopy={dynamicLink} myLinkName={myLinkName} eventId={eventId} />
        )}
        <Container container className="containerAdjust" sx={{ height: user ? "90%" : "100%" }} justifyContent={"center"} alignItems={"center"}>
          <Grid container alignItems={"center"} justifyContent={"center"} sx={{ height: "100%" }} py={5}>
            <Grid item md={12} className="createCard" p={2} sx={{ height: "100%" }}>
              <Grid container sx={{ height: "100%" }}>
                <Grid item md={4} p={2} className="cardBodyHalf">
                  {location.state?.arrow && (
                    <>
                      <Grid className="backSpaceIcon" onClick={() => navigate(-1)} sx={{ cursor: "pointer" }}>
                        <KeyboardBackspaceIcon />
                      </Grid>
                     
                    </>
                  )}
                  {/* {eventSlot?.profilePic && (
                    <Grid className="profileLetter profilePictureBlock">
                      <img src={eventSlot?.profilePic} alt="profileImg" />
                    </Grid>
                  )} */}
                  <Grid>
                  {brandingLogo ? (
                        <img src={brandingLogo} alt="Branding Logo" className="brandingLogo" style={{ width: "100%", height: "250px", objectFit: "scale-down", marginTop: "-4rem" }} />
                      ) : (
                        <Typography className="backSpaceText">
                          {organizationName}
                        </Typography>
                      )}

                  </Grid>
                  <Grid mt={-4} mb={4}>
                    <Divider />
                  </Grid>
                  <Typography className="cardBodyProfileName">
                    {eventSlot?.userName}
                  </Typography>
                  <Typography className="eventName">
                    {eventSlot?.name}
                  </Typography>
                  <Grid container gap={2} pt={3}>
                    <AccessTimeIcon className="timeIcon" />
                    <Typography className="timeText">
                      {formattedDuration}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item md={8} sx={{ height: "100%" }}>
                  <Grid sx={{ height: "10%" }}>
                    <Grid>
                      <Typography px={4} className="eventName">
                        Select a Date & Time
                      </Typography>
                    </Grid>
                    <Link href={ user ? `${window.location.origin}/app/events` : `${window.location.origin}`}>
                      <Grid className="overflowBranding">
                        <Grid className="brnadingBlock">
                          <Typography>powered by</Typography>
                          <Typography>Krifly</Typography>
                        </Grid>
                      </Grid>
                    </Link>
                  </Grid>
                  <Grid container sx={{ height: "90%" }}>
                    <Grid container item md={7} justifyContent={"center"} sx={{ height: "100%" }} className="calendarBlock">
                    <Calendar
                            value={selectedDate.toDate()}
                            onChange={(date) => setSelectedDate(dayjs(date))}
                            format="YYYY-MM-DD"
                            mapDays={({ date }) => {
                              let isAvailable = availableDates.some((avDate) =>
                                avDate.isSame(date, "day")
                              );
                              if (!isAvailable) {
                                return { disabled: true };
                              }
                              return { className: "highlighted-date" };
                            }}
                          />
                      <Grid mt={4} md={12}>
                        <Typography variant="h6" fontWeight={700}>
                          Time Zone
                        </Typography>
                        <Autocomplete
                          value={selectedTimeZone}
                          onChange={(event, newValue) =>
                            setSelectedTimeZone(newValue)
                          }
                          options={allTimeZones}
                          getOptionLabel={(option) => option}
                          renderInput={(params) => (
                            <TextField {...params} label="Select Time Zone" variant="outlined" sx={{ minWidth: 300, marginTop: 1 }} />
                          )}
                          sx={{ minWidth: 300, marginTop: 1 }}
                          isOptionEqualToValue={(option, value) =>
                            option === value
                          }
                        />
                      </Grid>
                    </Grid>
                    <Grid item md={5} sx={{ height: "100%" }}>
                          <Typography>
                            {selectedDate
                              ? selectedDate.format("dddd, MMMM D")
                              : "Select a date"}
                          </Typography>
                          <Grid
                            className="timeBlock"
                            sx={{ height: "90%", textAlign: "center" }}
                          >
                            {AllTimeSlots && AllTimeSlots.length > 0 ? (
                              AllTimeSlots
                            ) : (
                              <Typography pt={5}>
                                No available time slots for the selected date.
                              </Typography>
                            )}
                          </Grid>
                        </Grid>

                  </Grid>
                  <Grid container justifyContent={"space-between"} className="selectTimeSlot"></Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Grid>
      {/* <ErrorAlert open={errorAlrtOpen} handleClose={closeError} message={alrtMsg} /> */}
      <SuccessAlert open={successAlrtOpen} handleClose={closeSuccess} message={alrtMsg} />
      <BackdropWithLottie open={openLottie} handleClose={handleCloseLottie} />
    </>
  );
};
