import React from "react";
import { Grid } from "@mui/material";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import { NavLink } from "react-router-dom";
import ListItemText from "@mui/material/ListItemText";
import Logo from "../../assets/logo.png";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import LinkIcon from "@mui/icons-material/Link";

export const SideNav = () => {
  const navigate = useNavigate();

  const handleButtonClick = () => {
    navigate("/app/eventType");
  };

  return (
    <Grid p={1} sx={{ borderRight: "1px solid #CCCCCC", height: "100vh" }}>
      <Grid container justifyContent={"center"} py={3}>
        <NavLink to={`${window.location.origin}/app/events`} className="navLink logoImg">
          <img src={Logo} alt="logo" />
        </NavLink>
      </Grid>
      <Divider />
      <Button variant="contained" className="createBtn" startIcon={<AddIcon />} onClick={handleButtonClick}>
        Create
      </Button>
      <List className="list">
        <ListItem className="listItem">
          <NavLink to="/app/events" className={"navLink"}>
            <ListItemButton className="listItemBtn">
              <ListItemIcon className="listItemIcon">
                <LinkIcon />
              </ListItemIcon>
              <ListItemText primary={"Event types"} className="listItemText" />
            </ListItemButton>
          </NavLink>
        </ListItem>
        <ListItem className="listItem">
          <NavLink to="/app/availability" className={"navLink"}>
            <ListItemButton className="listItemBtn">
              <ListItemIcon className="listItemIcon">
                <AccessTimeIcon />
              </ListItemIcon>
              <ListItemText primary={"Availability"} className="listItemText" />
            </ListItemButton>
          </NavLink>
        </ListItem>
        <ListItem className="listItem">
          <NavLink to="/app/branding" className={"navLink"}>
            <ListItemButton className="listItemBtn">
              <ListItemIcon className="listItemIcon">
                <StarBorderIcon />
              </ListItemIcon>
              <ListItemText primary={"Branding"} className="listItemText" />
            </ListItemButton>
          </NavLink>
        </ListItem>
        <ListItem className="listItem">
          <NavLink to="/app/scheduledEvents" className={"navLink"}>
            <ListItemButton className="listItemBtn">
              <ListItemIcon className="listItemIcon">
                <svg className="svgIcon" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M4 1C4 0.447715 4.44772 0 5 0C5.55228 0 6 0.447715 6 1H11C11 0.447715
     11.4477 0 12 0C12.5523 0 13 0.447715 13 1C14.6569 1 16 2.34315 16 4V13C16 14.6569 14.6569 16 13 16H11C10.4477 16 10
      15.5523 10 15C10 14.4477 10.4477 14 11 14H13C13.5523 14 14 13.5523 14 13V7H2V13C2 13.5523 2.44772 14 3 14H5C5.55228
       14 6 14.4477 6 15C6 15.5523 5.55228 16 5 16H3C1.34315 16 0 14.6569 0 13V4C0 2.34315 1.34315 1 3 1H4ZM3 3C2.44772
        3 2 3.44772 2 4V5H14V4C14 3.44772 13.5523 3 13 3H3Z"
                    fill="currentColor"
                  ></path>
                  <path
                    d="M4 10C4 9.44772 4.44772 9 5 9C5.55228 9 6 9.44772 6 10C6 10.5523 5.55228 11 5 11C4.44772 11 4 10.5523 4 10Z"
                    fill="currentColor"
                  ></path>
                  <path
                    d="M8 9C7.44772 9 7 9.44772 7 10C7 10.5523 7.44772 11 8 11C8.55228 11 9 10.5523 9 10C9 9.44772 8.55228 9 8 9Z"
                    fill="currentColor"
                  ></path>
                  <path
                    d="M10 10C10 9.44772 10.4477 9 11 9C11.5523 9 12 9.44772 12 10C12 10.5523 11.5523 11 11 11C10.4477 11 10 10.5523
                   10 10Z"
                    fill="currentColor"
                  ></path>
                </svg>
              </ListItemIcon>
              <ListItemText primary={"Meetings"} className="listItemText" />
            </ListItemButton>
          </NavLink>
        </ListItem>
      </List>
    </Grid>
  );
};
