import React, { useState, useEffect } from "react";
import { Grid, Button, Popover, Typography } from "@mui/material";
import ArrowDropDownOutlinedIcon from "@mui/icons-material/ArrowDropDownOutlined";
import GroupOutlinedIcon from "@mui/icons-material/GroupOutlined";
import LinkOutlinedIcon from "@mui/icons-material/LinkOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import { useNavigate } from "react-router-dom";
import DeleteModal from "../deleteModal";
import { SuccessAlert, ErrorAlert } from "../alert";
import { useProfile } from "../contextProfile";
export const HeaderComponent = () => {
  const [user, setUser] = useState(null);
  const [firstLetter, setFirstLetter] = useState("");
  const navigate = useNavigate();
  const linkState = { link: user };
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [alrtMsg, setAlrtMsg] = useState("");
  const [successAlrtOpen, setSuccessAlrtOpen] = useState(false);
  const [errorAlrtOpen, setErrorAlrtOpen] = useState(false);
  const { headerProfile, setHeaderProfile } = useProfile();
  const handleOpenModal = () => {
    setAnchorEl(null);
    setOpenModal(true);
  };
  const handleCloseModal = () => setOpenModal(false);
  const closeSuccess = () => {
    setSuccessAlrtOpen(false);
  };

  const closeError = () => {
    setErrorAlrtOpen(false);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleProfile = () => {
    navigate("/app/profile");
    setAnchorEl(null);
  };
  const handleLink = () => {
    navigate("/app/linkPage", { state: linkState });
    setAnchorEl(null);
  };

  const getFirstLetter = (name) => {
    if (name) {
      const firstName = name.split(" ")[0];
      return firstName.charAt(0);
    }
    return "";
  };

  const handleLogout = () => {
    localStorage.clear();

    setAlrtMsg("Logged out successfully");
    setSuccessAlrtOpen(true);
    setTimeout(() => {
      navigate("/");
    }, 2000);
  };

  const getSession = async () => {
    const storedUser = localStorage.getItem("user"); // Removed optional chaining here
  
    if (storedUser) {
      try {
        // Ensure storedUser is a string before parsing
        if (typeof storedUser === 'string') {
          const parsedUser = JSON.parse(storedUser);
          setUser(parsedUser);
          setHeaderProfile(parsedUser?.profilePic);
          setFirstLetter(getFirstLetter(parsedUser.name));
        } else {
          console.error("Stored user is not a string");
        }
      } catch (error) {
        console.error("Error parsing user data:", error);
      }
    } else {
      console.log("No user found in localStorage");
    }
  };
  

  useEffect(() => {
    const intervalId = setInterval(() => {
      const storedUser = localStorage.getItem("user");
      if (storedUser) {
        clearInterval(intervalId);

        getSession();
      }
    }, 500); // Check every 500ms

    return () => clearInterval(intervalId);
  }, []);

  return (
    <>
      <Grid container justifyContent={"end"} className="innerHeader">
        {/* <Grid item>
                <Button
                className="profileButton"
                >
                <PersonAddAltOutlinedIcon  width={18} height={18}/>
                &nbsp;Invite user
                </Button>
            </Grid> */}
        <Grid item>
          <Button container gap={3} aria-describedby={id} onClick={handleClick}>
            <Grid className="profileLetter profilePictureBlock">
              {headerProfile && headerProfile !== null ? (
                <img src={headerProfile} alt="profile" />
              ) : (
                <Grid component={"span"}>{firstLetter}</Grid>
              )}
            </Grid>
            <Grid>
              <ArrowDropDownOutlinedIcon />
            </Grid>
          </Button>
        </Grid>
      </Grid>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        sx={{
          marginTop: "20px",
        }}
      >
        <Grid className="popover">
          <Grid>
            <Typography className="profileName">{user?.name}</Typography>
          </Grid>
          <Grid>
            <Typography className="accountHeading">Account settings</Typography>
            <Grid
              container
              alignItems={"center"}
              className="popoverText"
              gap={1}
              p={1.5}
              onClick={handleProfile}
            >
              <GroupOutlinedIcon />
              <Typography className="iconText">Profile</Typography>
            </Grid>
            <Grid
              container
              alignItems={"center"}
              className="popoverText"
              gap={1}
              p={1.5}
              onClick={handleLink}
            >
              <LinkOutlinedIcon />
              <Typography className="iconText">My Link</Typography>
            </Grid>
            <Grid
              container
              alignItems={"center"}
              className="popoverText"
              gap={1}
              p={1.5}
              onClick={handleOpenModal}
            >
              <LogoutOutlinedIcon />
              <Typography className="iconText">Logout</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Popover>

      <DeleteModal
        open={openModal}
        handleClose={handleCloseModal}
        handleLogout={handleLogout}
        text="Do you want to logout?"
      />

      <SuccessAlert
        alertMsg={alrtMsg}
        open={successAlrtOpen}
        close={closeSuccess}
      />
      <ErrorAlert alertMsg={alrtMsg} open={errorAlrtOpen} close={closeError} />
    </>
  );
};
