import React, { useState, useEffect } from 'react';
import { Grid, Typography, TextField, FormControl, Button } from '@mui/material';
import { useForm } from "react-hook-form";
import { useLocation } from 'react-router-dom';
import { ManagementServices } from '../services/managementServices';
import { SuccessAlert, ErrorAlert } from '../components/alert';

export const LinkPage = () => {
    const location = useLocation();
    const [alrtMsg, setAlrtMsg] = useState("");
    const [successAlrtOpen, setSuccessAlrtOpen] = useState(false);
    const [errorAlrtOpen, setErrorAlrtOpen] = useState(false);
    const { register, handleSubmit, formState: { errors }, setValue, clearErrors } = useForm();
    const userData = JSON.parse(localStorage.getItem('user') || '{}');

    const closeSuccess = () => {
        setSuccessAlrtOpen(false);
    };

    const closeError = () => {
        setErrorAlrtOpen(false);
    };

    const handleChange = (event) => {
        setValue('linkName', event.target.value);
        if (errors.linkName) {
            clearErrors('linkName');
        }
    };

    const onSubmit = async (data) => {
        if (!userData || !userData.googleId || !userData.id) {
            console.error("User data is not available");
            return;
        }
        const payload = {
            myLinkName: data.linkName,
            googleId: userData.googleId,
            userId: userData.id
        };
        try {
            const response = await ManagementServices.gettingLink(payload);
            const { data, status } = response;
            if (status === 1) {
                userData.myLinkName = data.user.myLinkName;
                localStorage.setItem('user', JSON.stringify(userData));
                setSuccessAlrtOpen(true);
                setAlrtMsg(response.message);
            } else  {
                setErrorAlrtOpen(true);
                setAlrtMsg(response.message);
            }
        } catch (error) {
            console.error("An error occurred:", error);
        }
    };
    useEffect(() => {
        if (userData && userData.myLinkName) {
            setValue('linkName', userData.myLinkName);
        }
    }, [userData, setValue]);

    return (
        <>
            <Grid>
                <Grid item md={5} py={5}>
                    <Grid>
                        <Typography className="timeText">Account details</Typography>
                        <Grid component={'h1'} className='pageTitle'>My link</Grid>
                        <Typography>Changing your Krifly URL will mean that all of your copied links will no longer work and will need to be updated.</Typography>
                    </Grid>
                    <Grid item md={12} pt={4} fullWidth>
                        <Grid component={'form'} onSubmit={handleSubmit(onSubmit)}>
                            <Grid container alignItems={'center'} gap={3}>
                                <Grid item md={2}>
                                    krifly.com/
                                </Grid>
                                <Grid item md={8}>
                                    <FormControl fullWidth>
                                    <TextField
                                        variant="outlined"
                                        placeholder="Link"
                                        fullWidth
                                        size="medium"
                                        className="eventTextField"
                                        sx={{ backgroundColor: 'white' }}
                                        {...register('linkName', { required: 'Link name is required' })}
                                        error={!!errors.linkName}
                                        helperText={errors.linkName?.message}
                                        onChange={(e) => {
                                            const value = e.target.value.toLowerCase().replace(/\s+/g, '-');
                                            handleChange({ ...e, target: { ...e.target, value } });
                                        }}
                                        inputProps={{ style: { textTransform: 'lowercase' } }}
                                        />

                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid item pt={4}>
                                <Button className="continueBtn" type='submit'>Save Changes</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <SuccessAlert alertMsg={alrtMsg} open={successAlrtOpen} close={closeSuccess} />
            {/* <ErrorAlert alertMsg={alrtMsg} open={errorAlrtOpen} close={closeError} /> */}
        </>
    );
};
