// ProtectedRoute.js
import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../components/authContext';

export const ProtectedRoute = ({ component : Component }) => {
  const { login } = useAuth();
  const isAuthenticated = JSON.parse(localStorage.getItem('isAuthenticated'));
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
      const token = queryParams.get('token');
      if(token || isAuthenticated === true) {
        login();
        return <Component />;
      }
    
      else {
        return <Navigate to="/" />;
      }
     
};