export const GoogleKeys =  {
    googleClientId: process.env.REACT_APP_CLIENT_ID,
    googleClientSecret: process.env.REACT_APP_CLIENT_SECRET,
    googleRedirectUri: process.env.REACT_APP_GOOGLE_REDIRECT_URI,
    googleScope: process.env.REACT_APP_GOOGLE_SCOPES,
    googleScopeLogin: process.env.REACT_APP_GOOGLE_SCOPES_LOGIN
}

export const apiConfig = {
     baseUrl: process.env.REACT_APP_BASE_URL,
     baseUrlGoogle: process.env.REACT_APP_BASE_URL_GOOGLE   
}