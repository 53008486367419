import React, { useEffect, useState } from "react";
import { Grid,Container, Typography, Button} from "@mui/material";
import Logo from '../../assets/logo.png';
import GoogleIcon from '@mui/icons-material/Google';
import { GoogleKeys } from"../../config";
export const WelcomeBackSignUp = () => {
    const [showEmail, setShowEmail] = useState(null);

    const handleLogin = () => {
        const clientId = GoogleKeys.googleClientId;
        const redirectUri = GoogleKeys.googleRedirectUri; 
        const scope = GoogleKeys.googleScope; 
        const responseType = 'code'; // Authorization code grant type
        const accessType = 'offline'; // Request refresh token
        const prompt = 'consent'; // Force consent screen
    
        // Construct the OAuth 2.0 URL
        const authUrl = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${clientId}&redirect_uri=${redirectUri}&response_type=${responseType}&scope=${scope}&access_type=${accessType}&prompt=${prompt}`;
    
        // Redirect to Google's OAuth 2.0 authorization server
        window.location.href = authUrl;
      };


    useEffect(() => {
        const storedUser = localStorage.getItem('user');
        if (storedUser) {
            const parsedUser = JSON.parse(storedUser);
            setShowEmail(parsedUser.email);
        }
    })
    return (
        <>
        <Container>
            <Grid container flexDirection={'column'} justifyContent={'space-between'} alignItems={'center'} py={5}>
                <Grid item md={4}>

                </Grid>
                <Grid item>
                    <img src ={Logo} alt="logo"/>
                </Grid>
                <Grid item mt={2}>
                    <Typography className="welcomeText">Welcome back, {showEmail}</Typography>
                </Grid>
                <Grid item conatiner justifyContent={'center'} mt={2}>
                    <Grid item  className="googleLoginBlock">
                        <Grid my={4} textAlign={'center'}>
                            <Button className="googleLoginBtn" onClick={handleLogin}>
                                <GoogleIcon className="googleIcon"/>
                                Login with Google
                            </Button>
                        </Grid>
                        <Grid className="dontAccountText">
                            <Typography component={'span'}>Don't have an account?</Typography>
                            <Typography component={'span'} className="span">Sign up</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Container>
        </>
    )
}
 