
import { IndividualRoutes } from "./individualRoutes";
import {CommonRoutes} from "./commonRoutes";
const MainRouter = () => {
    return (
        <>
          <CommonRoutes />
          <IndividualRoutes />    
        </>
    )
}
export default MainRouter;