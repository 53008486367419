import React, { useState } from "react";
import { SocialIconBtn } from "./socialIconBtn";  // Custom button component
import GoogleIcon from "./googleIcon";  // Your Google Icon component
import MicrosoftIcon from "./microSoftIcon";  // Your Microsoft Icon component
import { GoogleKeys } from "../config";  // Your Google OAuth keys
import axios from 'axios';  // Import Axios for making API requests

export const GoogleLoginButton = () => {
  const [loading, setLoading] = useState(false);  // Optional loading state

  // Handle Google login
  const handleGoogleLogin = () => {
    const clientId = GoogleKeys.googleClientId;
    const redirectUri = GoogleKeys.googleRedirectUri;
    const scope = GoogleKeys.googleScope;
    const responseType = "code";  // Authorization code grant type
    const accessType = "offline";  // Request refresh token
    const prompt = "consent";  // Force consent screen

    // Construct the OAuth 2.0 URL for Google login
    const authUrl = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${clientId}&redirect_uri=${redirectUri}&response_type=${responseType}&scope=${scope}&access_type=${accessType}&prompt=${prompt}`;
    window.location.href = authUrl;  // Redirect to Google login
  };

  // Handle Microsoft login
  const handleMicrosoftLogin = async () => {
    try {
      setLoading(true);  // Start loading state
      console.log("Initiating Microsoft login...");

      // Call the API to get the redirect URL using Axios
      const response = await window.open("https://krifly.com/api/microsoftapis/auth/redirect", "_self");

      // Check if the request was successful
      if (response.status === 200) {
        console.log("Microsoft login response data:", response.data);

        // Check if the response contains a redirectUrl to forward the user to Microsoft OAuth
        if (response.data.redirectUrl) {
          console.log("Redirecting user to:", response.data.redirectUrl);
          window.location.href = response.data.redirectUrl;  // Redirect to Microsoft OAuth URL
        } else {
          console.error("No redirect URL provided in response.");
        }
      } else {
        throw new Error(`Failed to login with Microsoft. Status: ${response.status}`);
      }
    } catch (error) {
      console.error("Error during Microsoft login:", error);
    } finally {
      setLoading(false);  // Stop loading state
    }
  };

  return (
    <>
      {/* Google Login Button */}
      <SocialIconBtn
        icon={GoogleIcon}
        label="Sign up with Google"
        backgroundColor="#006BFF"
        borderColor="#006BFF"
        googleLogin={handleGoogleLogin}  // Pass Google login handler
      />

      {/* Microsoft Login Button */}
      <SocialIconBtn
        icon={MicrosoftIcon}
        label={loading ? "Loading..." : "Sign up with Microsoft"}  // Display loading state
        backgroundColor="rgb(11, 53, 88)"
        borderColor="#006BFF"
        googleLogin={handleMicrosoftLogin}  // Pass Microsoft login handler (we use `googleLogin` prop for both)
      />
    </>
  );
};
