import React from "react";
import { Grid, Container, Typography, Button } from "@mui/material";
import Logo from "../assets/logo.png";
import GoogleIcon from "../components/googleIcon";
import MicroSoftIcon from "../components/microSoftIcon";

export const NormalLogin = () => {
  return (
    <Container>
      <Grid container flexDirection={"column"} justifyContent={"space-between"} alignItems={"center"} py={5}>
        <Grid item>
          <img src={Logo} alt="logo" />
        </Grid>
        <Grid item mt={2}>
          <Typography className="welcomeText">
            Log into your Krifly account
          </Typography>
        </Grid>
        <Grid item conatiner justifyContent={"center"} mt={2}>
          <Grid item className="normalLoginBlock">
            <Grid>
              <Grid className="normalLabelText">
                Enter your email to get started
              </Grid>
              <Grid>
                <input placeholder="Enter your email" className="normalInputField" />
              </Grid>
              <Grid my={2}>
                <Button className="btn signUpBtnText">Get Started</Button>
              </Grid>
              <Grid className="orDiv">
                <Grid component={"p"} className="orText" sx={{ color: "black" }}>
                  OR
                </Grid>
              </Grid>
              <Grid>
                <Grid>
                  <Button className="normalLoginBtn" startIcon={<GoogleIcon />}>
                    Login with Google
                  </Button>
                </Grid>
                <Grid>
                  <Button className="normalLoginBtn" startIcon={<MicroSoftIcon />}>
                    Login with microsoft
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid className="dontAccountText" sx={{ textAlign: "left" }}>
              <Typography component={"span"}>Don't have an account?</Typography>
              <Typography component={"span"} className="span"> Sign up </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};