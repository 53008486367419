// WelcomeBackLogin Component
import React, { useEffect, useState } from "react";
import { Grid, Container, Typography, Button } from "@mui/material";
import Logo from "../../assets/logo.png";
import GoogleIcon from "@mui/icons-material/Google";
import { GoogleKeys } from "../../config";
import { useNavigate } from "react-router-dom";
import MicroSoftIcon from "../../components/microSoftIcon";

export const WelcomeBackLogin = () => {
  const [loading, setLoading] = useState(false);
  const [showEmail, setShowEmail] = useState("");
  const [loginType, setLoginType] = useState(""); // New state for loginType
  const navigate = useNavigate();

  const handleGoogleLogin = () => {
    const clientId = GoogleKeys.googleClientId;
    const redirectUri = GoogleKeys.googleRedirectUri;
    const scope = GoogleKeys.googleScope;
    const responseType = "code";
    const accessType = "offline";
    const prompt = "consent";
    const authUrl = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${clientId}&redirect_uri=${redirectUri}&response_type=${responseType}&scope=${scope}&access_type=${accessType}&prompt=${prompt}`;
    window.location.href = authUrl;
  };

  useEffect(() => {
    const email = localStorage.getItem("email");
    const loginType = localStorage.getItem("loginType"); // Retrieve loginType
    if (email) {
      setShowEmail(email);
    }
    if (loginType) {
      setLoginType(loginType);
    }
  }, []);
  const handleMicrosoftLogin = async () => {
    try {
      setLoading(true);  // Start loading state
      console.log("Initiating Microsoft login...");

      // Call the API to get the redirect URL using Axios
      const response = await window.open("https://krifly.com/api/microsoftapis/auth/redirect", "_self");

      // Check if the request was successful
      if (response.status === 200) {
        console.log("Microsoft login response data:", response.data);

        // Check if the response contains a redirectUrl to forward the user to Microsoft OAuth
        if (response.data.redirectUrl) {
          console.log("Redirecting user to:", response.data.redirectUrl);
          window.location.href = response.data.redirectUrl;  // Redirect to Microsoft OAuth URL
        } else {
          console.error("No redirect URL provided in response.");
        }
      } else {
        throw new Error(`Failed to login with Microsoft. Status: ${response.status}`);
      }
    } catch (error) {
      console.error("Error during Microsoft login:", error);
    } finally {
      setLoading(false);  // Stop loading state
    }
  };
  return (
    <Container>
      <Grid container flexDirection={"column"} justifyContent={"space-between"} alignItems={"center"} py={5}>
        <Grid item md={4}></Grid>
        <Grid item>
          <img src={Logo} alt="logo" />
        </Grid>
        <Grid item mt={2}>
          <Typography className="welcomeText">Welcome back, {showEmail}</Typography>
        </Grid>
        <Grid item container justifyContent={"center"} mt={2}>
          <Grid item className="googleLoginBlock">
            <Grid my={4} textAlign={"center"}>
              {loginType === "google" ? (
                <Button className="googleLoginBtn" onClick={handleGoogleLogin}>
                  <GoogleIcon className="googleIcon" />
                  Login with Google
                </Button>
              ) : loginType === "microsoft" ? (
                <Button className="microsoftloginbtn" onClick={handleMicrosoftLogin} >
                  <MicroSoftIcon className="googleIcon"  backgroundColor="rgb(11, 53, 88)"
        borderColor="#006BFF" />
                  Login with Microsoft
                </Button>
              ) : null}
            </Grid>
            <Grid className="dontAccountText">
              <Typography component={"span"}>Don't have an account?</Typography>
              <Typography sx={{ cursor: "pointer" }} component={"span"} className="span" onClick={() => navigate("/signup")}>
                Sign up
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};
